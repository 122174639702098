<template>
  <div class="home">
    <h1>Dashboard</h1>
  </div>
</template>

<script>

  export default {
    name: 'Home',
    components: {
    }
  }
</script>